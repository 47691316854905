import React from "react";
const contact = "+977-9852672355";

function Cta() {
  return (
    <div className="font-poppins">
      <section className="py-16 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="mb-5 text-3xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight  capitalize">
              Don't hesitate to contact us
            </h2>
            <p className="mb-7 text-lg text-gray-600 font-medium">
            Our team is always ready to assist you with any questions or concerns you may have. Please don't hesitate to reach out to us for help with your purchases or any other queries. 
            </p>
            <div className="mb-11 md:inline-block">
              <button
                className="uppercase tracking-wider text-white py-4 px-6 w-full font-semibold shadow-4xl focus:ring focus:ring-indigo-300 bg-black rounded-full hover:bg-opacity-90 md:w-auto"
                type="button"
              >
                <a className="text-lg tracking-wide" href={`tel:${contact}`}>Click here to Call</a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cta;
