import React from "react";
import asset from "../assets/products/5.png";
function About() {
  return (
    <section className="bg-white dark:bg-gray-900 font-poppins">
      <div className="grid max-w-screen-xl py-8 mx-auto px-3 md:px-0 lg:gap-8 lg:py-16 lg:grid-cols-12">
      <div className="lg:mt-0 lg:col-span-5 lg:flex">
          <img
          className="object-cover w-[600px]"
            src={asset}
            alt="mockup"
          />
        </div>
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-5xl capitalize">
          Welcome to our one-stop-shop for all your footwear and cosmetic needs!
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
          Our store offers a wide variety of shoes, slippers, and cosmetics for women, men, and children. We pride ourselves on providing high-quality products at affordable prices, and our friendly staff is always ready to assist you with any questions you may have. Whether you're looking for stylish sneakers, cozy slippers, or the latest beauty products, we have everything you need to complete your look. Shop with us today and experience the convenience of finding all your favorite products in one place!
          </p>

          <a
            href="#"
            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-black rounded-full hover:bg-black hover:text-white transition"
          >
            Learn More
          </a>
        </div>
       
      </div>
    </section>
  );
}

export default About;
