import React from "react";
import { GiRunningShoe } from "react-icons/gi";
import { FaRegEye } from "react-icons/fa";
import { SiFandom } from "react-icons/si";
import { IoLogoElectron } from "react-icons/io5";
function Services() {
  const elements = [
    {
      id: 1,
      name: "Sandals, Slippers, Shoes",
      content:
        "We offer a wide selection of sandals, slippers, and shoes for men, women, and children. Our collection includes comfortable and stylish options for all occasions, from casual wear to formal events.",
      icon: <GiRunningShoe />,
    },
    {
      id: 2,
      name: "Cosmetics Product",
      content:
        " Our cosmetics collection includes high-quality makeup and skincare products from top brands. From lipsticks and eyeshadows to serums and creams, we have everything you need to look and feel your best. ",
        icon: <FaRegEye />,
    },
    {
      id: 3,
      name: "Fancy Accessories Products",
      content:
        "Our collection of fancy accessories includes jewelry, bags, hats, and other stylish items. These products are perfect for adding a touch of elegance to any outfit.",
        icon: <SiFandom />,
    },
    {
      id: 4,
      name: "Electronics Parts",
      content:
        "We offer a selection of high-quality electronics parts for those who enjoy tinkering with electronics. With our affordable prices and reliable products, we're the perfect choice for all your electronics needs.",
        icon: <IoLogoElectron />,
    },
  ];
  return (
    <section id="services" className="font-poppins py-4 md:py-16 mb-6 px-3 md:px-0">
      <div className="container mx-auto">
        <div className="flex flex-col items-center justify-center pb-8 space-y-3">
          <h1 className="text-3xl md:text-4xl text-black py-0 md:py-8 uppercase font-bold text-center">
            What we offer
          </h1>
          <p className="w-full px-3 md:px-0 md:w-2/3 text-center">
          We offer a wide range of shoes, slippers, and cosmetics at affordable prices. Our collection includes trendy and classic styles for all ages and genders. Shop with us for convenient one-stop-shopping for all your footwear and beauty needs.
          </p>
        </div>

        <div className="grid grid-cols-1 gap-5 mt-12 sm:grid-cols-4 lg:mt-20 lg:gap-x-12">
          {elements.map(function (element) {
            const { id, name, content,icon } = element;
            return (

          <div key={id} className="transition-all duration-200 bg-white hover:shadow-lg">
            <div className="py-10 px-9">
             <p className="text-6xl text-black">{icon}</p>
              <h3 className="mt-8 text-lg font-semibold text-black">
                {name}
              </h3>
              <p className="mt-4 text-base text-gray-700">
                {content}
              </p>
            </div>
          </div>

            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Services;
