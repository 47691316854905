import React from "react";
import heroImage from "../assets/hero.png";
// ------------------------------- Component Contents -------------------------------
const heading = "discover all modern shoes slippers sandals and cosmetic items";
const subHeading =
  "Explore our Collection of branded shoes, slippers and cosmetic items, all in one place";
const phone = "977-9852672355";
// ------------------------------- Component Contents Ends-------------------------------
function Hero() {
  return (
    <section className="bg-white font-poppins">
      <div className="max-w-screen-xl px-4 py-8 md:py-16 mx-auto flex justify-between">
        <div className="mr-auto mt-16 lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-4xl text-black font-extrabold text-purpleDark uppercase leading-none md:text-5xl xl:text-6xl">
            {heading}
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
            
            {subHeading}
          </p>

          <div>
            <a
              href={`tel:${phone}`}
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-xl font-medium text-center rounded-full bg-black text-white hover:bg-primaryDark"
            >
             {phone}
            </a>
          </div>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex lg:flex-end">
          <img src={heroImage} className="object-cover w-[600px]"/>
        </div>
      </div>
    </section>
  );
}

export default Hero;
