import React from "react";
import { AiFillStar } from "react-icons/ai";
function Testimonials() {
  const contentOne =
    "I am so happy with my purchase from this store! The quality of the shoes and cosmetics is top-notch, and the prices are unbeatable. I will definitely be coming back for more.";
  const contentTwo =
    "I had a great experience shopping with this store. The staff was friendly and helpful, and the selection of shoes and cosmetics was impressive. I found exactly what I was looking for, and I couldn't be happier with my purchase.";
  const contentThree =
    "I highly recommend this store to anyone looking for high-quality footwear and cosmetics. The products are stylish and comfortable, and the customer service is exceptional. I will definitely be a returning customer!";
  return (
    <section
      id="testimonials"
      className="font-poppins py-10 bg-gray-100 sm:py-16 lg:py-24"
    >
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 sm:text-4xl lg:text-5xl capitalize">
            Our happy clients say about us
          </h2>
        </div>

        <div className="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full sm:mt-12 lg:mt-20 lg:grid-cols-3 gap-x-6 xl:gap-x-12 gap-y-6">
          <div className="bg-white rounded-xl p-4 space-y-5">
            <div className="flex items-center justify-center text-yellow-400">
            <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar />
            </div>
            <div className="text-center">{contentOne}</div>
            <div className="name">
              <p className="text-sm font-semibold">Suraj Katwal</p>
              <p className="text-xs">Digital Marketer</p>
            </div>
          </div>
          <div className="bg-white rounded-xl p-4 space-y-5">
            <div className="flex items-center justify-center text-yellow-400">
            <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar />
            </div>
            <div className="text-center">{contentTwo}</div>
            <div className="name">
              <p className="text-sm font-semibold">Keshar Bahadur Dahal</p>
              <p className="text-xs">Lawyer</p>
            </div>
          </div>
          <div className="bg-white rounded-xl p-4 space-y-5">
            <div className="flex items-center justify-center text-yellow-400">
            <AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar />
            </div>
            <div className="text-center">{contentThree}</div>
            <div className="name">
              <p className="text-sm font-semibold">Sandeep Pokhrel</p>
              <p className="text-xs">Student</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
